<template>
  <div>
    <div>
      <v-dialog
        v-model="dialog"
        :overlay-color="'#000000'"
        :overlay-opacity="0.9"
        max-width="1000"
      >
        <v-card color="secondary" class="overflow-hidden">
          <v-row>
            <v-col cols="8">
              <search-report
                class="ma-10"
                :query="queryString"
                :flaggedCount="String(flaggedConversations.length)"
                :conversationCount="String(searchStats.num_conversations)"
                :messageCount="String(searchStats.num_messages)"
                :sourceCount="String(searchStats.num_sources)"
                :authorCount="String(searchStats.num_authors)"
                :conversationTotal="String(darkReconTotals.num_conversations)"
                :messageTotal="String(darkReconTotals.num_messages)"
                :sourceTotal="String(darkReconTotals.num_sources)"
                :authorTotal="String(darkReconTotals.num_authors)"
              />
            </v-col>
            <v-col cols="4">
              <v-icon
                class="mt-4 mr-4 float-right"
                @click="dialog = false"
                color="white"
                >mdi-close</v-icon
              >
              <center>
                <v-btn
                  depressed
                  color="#e86051"
                  class="mt-10 mb-5"
                  @click="copyImage"
                  >Copy Image</v-btn
                >
              </center>
              <div id="message-preview">
                <div v-for="(preview, i) in flaggedPreview" :key="i">
                  <cart-item
                    :selectConversation="selectConversation"
                    :preview="preview"
                    :key="i"
                  >
                  </cart-item>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <search-bar
        :submitHandler="submitSearch"
        placeholder="Enter a dark web search"
        :startValue="startValue"
      >
        <v-row>
          <v-col></v-col>
          <v-col>
            <help-button
              :title="title"
              :content="content"
              :side="'left'"
            ></help-button>
          </v-col>
          <v-col>
            <v-icon
              v-if="flaggedConversations.length == 0"
              color="#58abe7"
              @click="toggleDialog"
              >mdi-chart-scatter-plot</v-icon
            >

            <v-badge
              v-if="flaggedConversations.length > 0"
              bottom
              color="#e86051"
              :content="flaggedConversations.length"
              offset-x="10"
              offset-y="10"
            >
              <v-icon color="#58abe7" @click="toggleDialog"
                >mdi-chart-scatter-plot</v-icon
              >
            </v-badge>
          </v-col>
        </v-row>
      </search-bar>
      <v-row class="mt-2">
        <v-col cols="3">
          <date-picker
            :submitHandler="updateDateRangeStart"
            :initialStartDate="initialStartDate"
            :range="false"
            :clearInput="clearStart"
            ref="datePickerStartDate"
          />
          <v-icon
            class="float-right mr-10 mt-n7"
            @click="
              updateDateRangeStart([null]);
              clearStart = true;
            "
            >mdi-close</v-icon
          >
        </v-col>
        <v-col cols="3" class="ml-n10">
          <date-picker
            :submitHandler="updateDateRangeEnd"
            :initialStartDate="initialEndDate"
            :range="false"
            :clearInput="clearEnd"
            ref="datePickerEndDate"
          /><v-icon
            class="float-right mr-10 mt-n7"
            @click="
              updateDateRangeEnd([null]);
              clearEnd = true;
            "
            >mdi-close</v-icon
          >
        </v-col>
        <v-col cols="4" class="mt-n4">
          <v-row>
            <v-col class="pa-0 ml-3">
              <div v-if="conversation">
                <div
                  v-if="
                    conversation.length == 0 &&
                    showEmpty == true &&
                    isLoading == false
                  "
                  class="body-2 pt-10"
                >
                  0 messages
                </div>
              </div>
              <div v-if="stats">
                <div
                  v-if="
                    stats &&
                    conversation.length > 0 &&
                    showEmpty == true &&
                    isLoading == false
                  "
                  class="body-2 pt-10"
                >
                  {{ stats.num_messages }} messages
                </div>
              </div>
            </v-col>
            <v-col>
              <sort-list
                v-if="queryString"
                :items="items"
                v-model="selected"
                class="float-right mr-n4 mt-4"
              ></sort-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <div v-if="conversation">
          <h3
            v-if="
              conversation.length == 0 &&
              showEmpty == true &&
              isLoading == false
            "
            class="pa-5 font-weight-thin"
          >
            No results found
          </h3>
        </div>
        <skeleton-loader
          :header="true"
          :type="'card'"
          class="mt-5 ml-2"
          v-if="isLoading == true && conversation.length == 0"
        ></skeleton-loader>
        <!-- <conversation-list
          :conversations="conversation"
          :isPermalink="isPermalink"
          :searchResults="searchResults"
          :selected="flaggedConversations"
          @selectConversation="selectConversation"
          v-model="panel"
          v-if="conversation.length == 0"
        ></conversation-list> -->

        <conversation-list
          :conversations="conversation"
          :selected="flaggedConversations"
          :isPermalink="isPermalink"
          :searchResults="searchResults"
          @selectConversation="selectConversation"
          v-model="panel"
          v-if="conversation.length > 0"
        ></conversation-list>
      </v-row>
      <v-fade-transition>
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          outlined
          color="white"
          @click="toTop"
        >
          <v-icon>mdi-arrow-up-bold</v-icon>
        </v-btn>
      </v-fade-transition>
      <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        v-if="conversation.length > 10"
      >
        <div slot="spinner">&nbsp;</div>
        <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
        <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
      </infinite-loading>
      <v-snackbar v-model="snackBar" color="#112748" elevation="6">
        All messages loaded
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import InfiniteLoading from "vue-infinite-loading";
import CartItem from "@/components/CartItem.vue";
import FilterBar from "@/components/FilterBar.vue";
import SearchBar from "@/components/SearchBar.vue";
import SearchReport from "@/components/SearchReport.vue";
import ConversationList from "@/components/ConversationList.vue";
import DatePicker from "@/components/DatePicker.vue";
import HelpButton from "@/components/HelpButton.vue";
import SortList from "@/components/SortList.vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import EventBus from "@/clients/event-bus";
import moment from "moment";
import { formatDate } from "@/utils/common";
import { numFormatComma } from "@/utils/common";

export default {
  name: "DarkRecon",
  components: {
    CartItem,
    ConversationList,
    FilterBar,
    SearchBar,
    DatePicker,
    HelpButton,
    SortList,
    SearchReport,
    SkeletonLoader,
    InfiniteLoading,
  },
  data: () => ({
    moment: moment,
    formatDate: formatDate,
    initialStartDate: null,
    initialEndDate: null,
    clearStart: false,
    clearEnd: false,
    startValue: "",
    dialog: false,
    isPermalink: false,
    panel: [],
    initialLoad: true,
    initialLoadParams: true,
    isLoading: false,
    infiniteId: 1,
    stopLoading: false,
    snackBar: false,
    fab: false,
    showEmpty: false,
    selected: "Relevance",
    title: `How to use search`,
    content: `• The default operator for space is AND.<br/>
ex. <span style="color:#E86051">fullz cards</span> will search for matches with "fullz AND cards"<br/><br/>

• <span style="color:#E86051">+</span> signifies AND operation<br/>
ex. <span style="color:#E86051">fullz + cards</span> will search for matches of "fullz AND cards"<br/><br/>

• <span style="color:#E86051">|</span> signifies OR operation<br/>
ex. <span style="color:#E86051">fullz | cards</span> will search for matches of "fullz OR cards"<br/><br/>

• <span style="color:#E86051">-</span> negates a single token<br/>
ex. <span style="color:#E86051">fullz -cards</span> will search for matches of "fullz WITHOUT cards"<br/><br/>

• <span style="color:#E86051">"</span> wraps a number of tokens to signify a phrase for searching<br/>
ex. <span style="color:#E86051">"fullz cards"</span> searches for the exact phrase "fullz cards"<br/><br/>

• <span style="color:#E86051">*</span> at the end of a term signifies a prefix query (NOTE: leading wildcards are NOT supported)<br/>
ex. <span style="color:#E86051">fullz*</span> searches for words that begins with "fullz" i.e. "fullzies" or "fullz0rs"<br/><br/>

• <span style="color:#E86051">( and )</span> signify precedence - mainly for clarity for us users :)<br/>
ex. how ES will interpret <span style="color:#E86051">fullz AND cards OR bins</span> isn't as clear as how it will interpret <span style="color:#E86051">(fullz AND cards) OR bins</span>: "match any document containing the strings 'fullz' and 'bins', OR that contains the word 'bins'"</p>`,
    items: ["Relevance", "Newest First", "Oldest First"],
    flaggedConversations: [],
    searchResults: true,
  }),
  beforeCreate() {
    store.commit("darkRecon/clearConversation");
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      if (this.stoppingConversationMessages == true) {
        setTimeout(function () {
          $state.loaded();
          return;
        }, 200);
      }
      store.dispatch("darkRecon/addConversations").then((result) => {
        if (result === null) {
          $state.loaded();
          return;
        }
        if (result.length > 0) {
          $state.loaded();
          return;
        }
        if (result.length === 0) {
          $state.complete();
          return;
        }
      });
    },
    copyImage() {
      let myImage = document.getElementById("search-report-image");
      let canvas = document.createElement("canvas");
      canvas.width = 595;
      canvas.height = 374;
      const ctx = canvas.getContext("2d");

      let svgURL = new XMLSerializer().serializeToString(myImage);
      let img = new Image();
      img.onload = function () {
        ctx.drawImage(this, 0, 0);
      };

      img.src =
        "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svgURL);

      setTimeout(function () {
        canvas.toBlob(function (blob) {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([item]).then(function () {});
        });
      }, 10);

      navigator.clipboard.writeText("hey");
    },
    toggleDialog: function () {
      this.dialog = !this.dialog;
    },
    selectConversation: function (convoId) {
      if (this.flaggedConversations.includes(convoId)) {
        this.flaggedConversations = this.flaggedConversations.filter(
          (convo) => convo != convoId
        );
      } else {
        this.flaggedConversations.push(convoId);
      }
    },
    submitSearch: function (queryString) {
      this.searchResults = false;
      this.infiniteId += 1;
      this.panel.length = 0;
      if (this.initialLoadParams == false) {
        store.dispatch("darkRecon/update", {
          key: "queryString",
          value: queryString,
        });
      }

      if (this.initialLoad == true) {
        this.initialLoad = false;
      }
      this.isLoading = true;
      this.searchResults = true;
    },
    updateDateRangeStart: function (dates) {
      this.searchResults = false;
      if (dates[0]) {
        this.clearStart = false;
      }
      console.debug(dates);
      store.commit("darkRecon/replaceValue", {
        key: "date_start",
        value: dates[0],
      });
      if (this.initialLoadParams == false) {
        store.dispatch("darkRecon/update", {
          key: "date_start",
          value: dates[0],
        });
      }
      this.searchResults = true;
    },
    updateDateRangeEnd: function (dates) {
      this.searchResults = false;
      if (dates[0]) {
        this.clearEnd = false;
      }
      console.debug(dates);
      store.commit("darkRecon/replaceValue", {
        key: "date_end",
        value: dates[0],
      });
      store.dispatch("darkRecon/update", {
        key: "date_end",
        value: dates[0],
      });
      this.initialLoadParams = false;
      this.searchResults = true;
    },
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);
        if (key == "q" && val !== "null") {
          this.startValue = val;
          store.commit("darkRecon/replaceValue", {
            key: "queryString",
            value: val,
          });
        }
        if (key == "sort_by" && val !== "null") {
          if (val == "relevance") {
            this.selected = "Relevance";
          }
          if (val == "date") {
            this.selected = "Newest First";
          }
        }
        if (key == "sort_order" && val !== "null") {
          if (val == "asc") {
            this.selected = "Oldest First";
          }
        }
        if (key == "date_start" && val !== "null") {
          this.$refs.datePickerStartDate.dateData =
            moment(val).format("YYYY-MM-DD");
          store.commit("darkRecon/replaceValue", {
            key: "date_start",
            value: val,
          });
        }
        if (key == "date_end" && val !== "null") {
          this.$refs.datePickerEndDate.dateData =
            moment(val).format("YYYY-MM-DD");
          store.commit("darkRecon/replaceValue", {
            key: "date_end",
            value: val,
          });
        }
      }
    },
  },
  beforeMount() {
    store.dispatch("darkRecon/getTotals");
  },
  mounted() {
    const reloaded = window.localStorage.getItem("reloaded");
    if (reloaded !== "true") {
      window.localStorage.setItem("reloaded", "true");
    }
    EventBus.$on("DATA_LOADING", (payload) => {
      this.isLoading = false;
    });
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.$refs.datePickerStartDate.dateData = moment()
      .subtract(365, "days")
      .format("YYYY-MM-DD");
    this.$refs.datePickerEndDate.dateData = moment().format("YYYY-MM-DD");
    this.updateUrlParams(urlParams);
  },

  computed: {
    conversation() {
      return store.state.darkRecon.conversation;
    },
    flaggedPreview() {
      const previews = [];
      for (const convoId of this.flaggedConversations) {
        const convo = this.conversation.filter(
          (c) => c.conversation_id == convoId
        )[0].title;
        previews.push({ convo, convoId });
      }
      return previews;
    },
    stoppingConversationMessages() {
      return store.state.darkRecon.stoppingConversationMessages;
    },
    stats() {
      const stats = store.state.darkRecon.stats;
      for (let [key, val] of Object.entries(stats)) {
        stats[key] = numFormatComma(val);
      }
      return stats;
    },
    queryString() {
      return store.state.darkRecon.queryString;
    },
    search() {
      return store.state.darkRecon.queryString;
    },
    searchStats() {
      const searchStats = store.state.darkRecon.searchStats;
      for (let [key, val] of Object.entries(searchStats)) {
        searchStats[key] = numFormatComma(val);
      }
      return searchStats;
    },
    darkReconTotals() {
      return store.state.darkRecon.darkReconTotals;
    },
    filterOptions() {
      return {
        author: store.state.darkRecon.authorOptions,
        network: store.state.darkRecon.networkOptions,
        source: store.state.darkRecon.sourceOptions,
      };
    },
    filters() {
      const darkReconFilters = [
        {
          text: "Author",
          icon: "mdi-account-circle",
        },
        {
          text: "Network",
          icon: "mdi-wan",
        },
        {
          text: "Source",
          icon: "mdi-web-box",
        },
      ];

      for (const filter of darkReconFilters) {
        filter["items"] = this.filterOptions[filter.text.toLowerCase()];
      }
      return darkReconFilters;
    },
  },
  watch: {
    selected: {
      handler: function (val) {
        console.log(val);
        this.isLoading = true;
        let keyVal;
        let sortVal;
        if (val == "Relevance") {
          keyVal = "relevance";
          sortVal = "desc";
        }
        if (val == "Newest First") {
          keyVal = "date";
          sortVal = "desc";
        }
        if (val == "Oldest First") {
          keyVal = "date";
          sortVal = "asc";
        }
        store.commit("darkRecon/replaceValue", {
          key: "sortOrder",
          value: sortVal,
        });
        if (this.initialLoadParams == false) {
          store.dispatch("darkRecon/update", { key: "sortBy", value: keyVal });
        }
      },
    },
    conversation: {
      handler: function (val) {
        if (val !== null && val !== undefined) {
          if (val.length == 0 && this.initialLoad == false) {
            this.showEmpty = true;
          }
        }
      },

      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
.search-bar >>> .v-icon.v-icon.v-icon--link {
  min-width: 0px;
}

@media (max-width: 800px) {
  .search-bar >>> .v-icon.v-icon.v-icon--link {
    min-width: 100px;
  }
}

#message-preview {
  height: 330px;
  overflow-y: scroll;
}
</style>
