<template>
  <div @mouseenter="callback($event)" @mouseleave="stopHover">
    <v-expansion-panel-header
      :color="panelColor(isOpen)"
      style="cursor: default; overflow-wrap: anywhere"
    >
      <template v-slot="{ open }">
        <v-row class="pa-0 ma-0" style="min-height: 45px">
          <v-col class="pa-0" cols="11">
            <h2 class="font-weight-light" style="line-height: 1.5em">
              {{ conversation.title }}
            </h2>
          </v-col>
          <v-col v-if="isHovered || isSelected" class="pa-0" cols="1">
            <v-icon
              class="float-right pl-2"
              color="accent"
              style="pointer-events: auto"
              v-on:click.stop="selectConversation()"
              >{{ checkbox }}</v-icon
            >
            <a
              v-on:click.stop
              :href="'/darkrecon/conversations/' + conversation.conversation_id"
              ><v-icon
                class="float-right"
                color="accent"
                style="pointer-events: auto"
                >mdi-link-variant</v-icon
              ></a
            >
          </v-col>
        </v-row>

        <div v-if="!isPermalinkConvo" class="mb-4">
          <v-row v-for="(row, index) in grid(open)" :key="index">
            <v-col v-for="(item, ind) in row" :key="ind" cols="3" class="py-0">
              <v-icon small :color="iconColor(open)" class="pr-2">{{
                item.icon
              }}</v-icon>
              <span
                style="pointer-events: auto"
                v-if="
                  item.icon !== 'mdi-account-circle' &&
                  item.icon !== 'mdi-message-text'
                "
                :class="fontColor(open, item.icon)"
                >{{ item.text }}</span
              >

              <a
                v-if="item.icon == 'mdi-message-text'"
                style="
                  pointer-events: auto;
                  cursor: pointer;
                  text-decoration: none;
                  color: #fff;
                "
                v-on:click.stop
                :href="
                  '/darkrecon/conversations/' + conversation.conversation_id
                "
              >
                <span :class="fontColor(open, item.icon)">{{
                  item.text
                }}</span></a
              >

              <a
                v-if="item.icon == 'mdi-account-circle'"
                style="
                  pointer-events: auto;
                  cursor: pointer;
                  text-decoration: none;
                  color: #fff;
                "
                target="_blank"
                v-on:click.stop
                :href="
                  '/darkrecon/networks/' +
                  conversation.network.id +
                  '/sources/' +
                  conversation.source.id +
                  '/authors/' +
                  item.id
                "
              >
                <span :class="fontColor(open, item.icon)">{{
                  item.text
                }}</span></a
              >
            </v-col>
          </v-row>
        </div>
        <div v-if="isPermalinkConvo" class="mb-4">
          <v-row v-for="(row, index) in permalinkGrid(open)" :key="index">
            <v-col v-for="(item, ind) in row" cols="3" :key="ind" class="py-0">
              <v-icon small :color="iconColor(open)" class="pr-2">{{
                item.icon
              }}</v-icon>
              <span
                style="pointer-events: none"
                v-if="
                  item.icon !== 'mdi-account-circle' &&
                  item.icon !== 'mdi-message-text'
                "
                :class="fontColor(open, item.icon)"
                >{{ item.text }}</span
              >

              <a
                v-if="item.icon == 'mdi-message-text'"
                style="
                  pointer-events: auto;
                  cursor: pointer;
                  text-decoration: none;
                  color: #fff;
                "
                v-on:click.stop
                :href="
                  '/darkrecon/conversations/' + conversation.conversation_id
                "
              >
                <span :class="fontColor(open, item.icon)">{{
                  item.text
                }}</span></a
              >

              <a
                style="
                  pointer-events: auto;
                  cursor: pointer;
                  text-decoration: none;
                  color: #fff;
                "
                v-if="item.icon == 'mdi-account-circle'"
                target="_blank"
                v-on:click.stop
                :href="
                  '/darkrecon/networks/' +
                  conversation.network.id +
                  '/sources/' +
                  conversation.source.id +
                  '/authors/' +
                  item.id
                "
                ><span :class="fontColor(open, item.icon)">{{
                  item.text
                }}</span></a
              >
            </v-col>
          </v-row>
        </div>
        <v-row
          class="pa-0 ma-0 mb-2"
          v-if="!isPermalinkConvo"
          style="min-height: 30px"
        >
          <span v-if="relevant_msg" class="conversation-body-truncated"
            >{{ relevant_msg.body[0].content }}
          </span>
        </v-row>

        <v-row v-if="isOpen && !isPermalinkConvo" class="ml-1 mb-1"
          ><span
            style="cursor: pointer"
            class="text-caption text-decoration-underline"
            >Hide preview</span
          >
          <v-icon color="primary" style="margin-top: -2px"
            >mdi-menu-up</v-icon
          ></v-row
        >
        <v-row v-if="!isOpen && !isPermalinkConvo" class="ml-1 mb-1"
          ><span
            style="cursor: pointer"
            class="text-caption text-decoration-underline"
            >Show preview</span
          >
          <v-icon color="primary" style="margin-top: -2px"
            >mdi-menu-down</v-icon
          ></v-row
        >
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :color="panelColor(isOpen)">
      <conversation-detail
        v-if="messagesLoaded == true && isPermalinkConvo == false"
        :conversationId="conversation.conversation_id"
        :sourceId="conversation.source.id"
        :networkId="conversation.network.id"
        :messages="messageData"
        :isPermalinkDetail="this.isPermalinkConvo"
        :isMessageDetail="this.isMessageConvo"
        :isAuthorDetail="this.isAuthorConvo"
      />
      <conversation-detail
        v-if="isPermalinkConvo == true"
        :conversationId="conversation.conversation_id"
        :sourceId="conversation.source.id"
        :networkId="conversation.network.id"
        :messages="permalinkConversation.messages"
        :isPermalinkDetail="this.isPermalinkConvo"
        :isMessageDetail="this.isMessageConvo"
        :isAuthorDetail="this.isAuthorConvo"
      />
      <conversation-detail
        v-if="isAuthorConvo == true"
        :conversationId="conversation.conversation_id"
        :sourceId="conversation.source.id"
        :networkId="conversation.network.id"
        :messages="authorMessages"
        :isPermalinkDetail="this.isPermalinkConvo"
        :isMessageDetail="this.isMessageConvo"
        :isAuthorDetail="this.isAuthorConvo"
      />
      <div class="convoSkeletonLoader">
        <skeleton-loader
          :header="false"
          :type="'card'"
          v-if="
            messagesLoaded == false &&
            isPermalinkConvo == false &&
            isAuthorConvo == false
          "
        ></skeleton-loader>
      </div>
      <a
        v-on:click.stop
        :href="'/darkrecon/conversations/' + conversation.conversation_id"
        ><span
          class="caption mt-6 linkText"
          style="cursor: pointer !important; color: #fff"
          >View entire conversation</span
        ></a
      >
    </v-expansion-panel-content>
    <v-snackbar v-model="showClipboard" class="snackbarText">
      Copied!
      <v-btn :ripple="false" color="#333333" text @click="showClipboard = false"
        >Close</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import ConversationDetail from "@/components/ConversationDetail.vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import store from "../store/index";
import { formatDate } from "@/utils/common";
export default {
  name: "conversation",
  props: {
    conversation: {
      type: Object,
      default: () => {
        [];
      },
    },
    isPermalinkConvo: {
      type: Boolean,
      default: false,
    },
    isMessageConvo: {
      type: Boolean,
      default: false,
    },
    isAuthorConvo: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    messagesLoaded: false,
    messageData: [],
    showClipboard: false,
    moment: moment,
    isHovered: false,
    formatDate: formatDate,
  }),
  mounted() {
    if (this.isPermalinkConvo == true) {
      this.isOpen = true;
      this.handleHeader();
    }
  },
  watch: {
    messages: {
      handler: function () {
        if (
          this.messages.filter(
            (msg) => msg.conversation_id == this.conversation.conversation_id
          ).length > 0 &&
          this.messagesLoaded == false
        ) {
          const msgs = this.messages.filter(
            (msg) => msg.conversation_id == this.conversation.conversation_id
          )[0];
          this.messageData = msgs.messages;
          this.messagesLoaded = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    ConversationDetail,
    SkeletonLoader,
  },
  computed: {
    authorMessages() {
      let arr = [];
      for (
        let x = 0;
        x < store.state.darkRecon.threatActorMessages.length;
        x++
      ) {
        let convo = store.state.darkRecon.threatActorMessages[x];
        console.log(convo);
        if (
          convo.conversation_id == this.conversation.conversation_id &&
          convo.messages
        ) {
          for (let c = 0; c < convo.messages.length; c++) {
            let m = convo.messages[c];
            arr.push(m);
          }
          if (this.isAuthorConvo == true) {
            this.conversation.message = convo.messages[0];
          }
        }
      }

      return arr;
    },
    relevant_msg() {
      return this.conversation.message;
    },
    messages() {
      return store.state.darkRecon.messages;
    },

    permalinkConversation() {
      return store.state.darkRecon.permalinkConversation[0];
    },
    checkbox() {
      if (this.isSelected) {
        return "mdi-checkbox-marked";
      }
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    stopHover() {
      this.isHovered = false;
    },
    selectConversation() {
      this.$emit("selectConversation", this.conversation.conversation_id);
    },
    callback: function (e) {
      this.isHovered = true;
      this.$emit("mouseenter", e);
    },
    handleHeader() {
      document.getElementsByClassName(
        "v-expansion-panel-header"
      )[0].style.pointerEvents = "none";
    },
    copyText(text) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showClipboard = true;
    },
    iconColor(open) {
      if (open) {
        this.isOpen = true;
        return "accent";
      } else {
        this.isOpen = false;
        return "accent";
      }
    },
    fontColor(open, icon) {
      if (open || !open) {
        if (icon.length > 0) {
          return "text-body-2 text-decoration-underline";
        } else {
          return "text-body-2 grey--text";
        }
      } else {
        return "text-body-2 primary--text";
      }
    },
    panelColor(isOpen) {
      if (isOpen) {
        return "#0C1D37";
      } else {
        return "";
      }
    },
    grid(isOpen) {
      const dataGrid = [
        {
          icon: "mdi-account-circle",
          text: this.conversation.messages[0].author.name,
          id: this.conversation.messages[0].author.id,
        },
        {
          icon: "mdi-message-text",
          text: this.conversation.total_messages + ` messages`,
        },
        {
          icon: "",
          text: formatDate(this.conversation.messages[0].date),
        },
        {
          icon: "",
          text: this.conversation.source.id,
        },
      ];
      if (isOpen) {
        return [dataGrid.slice(0, 0), dataGrid.slice(0)];
      } else {
        return [dataGrid.slice(0, 0), dataGrid.slice(0)];
      }
    },
    permalinkGrid(isOpen) {
      const dataGrid = [
        {
          icon: "mdi-account-circle",
          text: this.conversation.messages[0].author.name,
          id: this.conversation.messages[0].author.id,
        },
        {
          icon: "mdi-message-text",
          text: this.conversation.total_messages + ` messages`,
        },
        {
          icon: "",
          text: formatDate(this.conversation.date),
        },
        { icon: "", text: this.conversation.source.id },
      ];
      if (isOpen) {
        return [dataGrid.slice(0, 0), dataGrid.slice(0)];
      } else {
        return [dataGrid.slice(0, 0), dataGrid.slice(0)];
      }
    },
  },
};
</script>

<style scoped>
.conversation-body-truncated {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.convoSkeletonLoader {
  max-width: 95%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.snackbarText .v-btn__content {
  margin-right: -25em;
  color: #e86051;
}
.linkText:hover {
  text-decoration: underline;
}
</style>
