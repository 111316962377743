<template>
  <div class="conversation-list">
    <v-row>
      <v-col>
        <v-data-iterator
          :items="conversations"
          item-key="id"
          hide-default-header
          hide-default-footer
          :no-data-text="''"
          :items-per-page="-1"
        >
          <template v-slot:default="{ items }">
            <v-expansion-panels multiple v-model="panel" @change="selection">
              <v-expansion-panel
                v-for="(item, index) in items"
                class="ma-2"
                :key="index"
                :id="item.conversation_id"
              >
                <conversation
                  :conversation="item"
                  :isPermalinkConvo="isPermalink"
                  :isMessageConvo="isMessage"
                  :isAuthorConvo="isAuthor"
                  :isSelected="isSelected(item.conversation_id)"
                  @mouseenter="openPanelSelection(item.conversation_id)"
                  @selectConversation="selectConversation"
                />
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Conversation from "@/components/Conversation.vue";
import store from "../store/index";

export default {
  name: "conversation-list",
  components: {
    Conversation,
  },
  props: {
    conversations: {
      type: Array,
      default() {
        return [];
      },
    },
    isPermalink: {
      type: Boolean,
      default: false,
    },
    isMessage: {
      type: Boolean,
      default: false,
    },
    isAuthor: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    panel: [],
    authorUpdated: [],
  }),
  methods: {
    isSelected: function (convoId) {
      if (this.selected.includes(convoId)) {
        return true;
      } else {
        return false;
      }
    },
    selectConversation: function (convoId) {
      this.$emit("selectConversation", convoId);
    },
    selection: function (openPanelIndexes) {
      this.$emit("input", openPanelIndexes);
    },
    openPanelSelection: function (id) {
      if (this.isPermalink == false && this.isAuthor == false) {
        for (let x = 0; x < this.conversation.length; x++) {
          let convo = this.conversation[x];
          if (convo.conversation_id == id && convo.messageLoaded == false) {
            store.dispatch("darkRecon/addMessages", convo);
          }
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.panel = this.value;
    }
  },
  computed: {
    conversation() {
      return store.state.darkRecon.conversation;
    },
    authorProfile() {
      return store.state.darkRecon.authorProfile;
    },
    authorMessages() {
      return store.state.darkRecon.threatActorMessages;
    },
  },
};
</script>

<style>
.conversation-list {
  margin: 0px 10px 10px 10px;
  font-family: "Roboto";
  width: 100%;
  max-width: 966px;
}
.conversation-body-truncated p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.conversation-list .v-expansion-panel-header {
  display: inline-block;
  line-height: 2;
  margin-top: 0em;
  min-height: 125px;
}
.conversation-list .v-expansion-panel::before {
  display: none;
}
.conversation-list .v-expansion-panel:not(:first-child)::after {
  display: none;
}
.conversation-list
  .v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  display: none;
}
.conversation-list .v-expansion-panel-header__icon {
  display: none;
}
</style>
