<template>
  <v-chip
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :outlined="!hover"
    color="secondary"
    class="mx-2"
  >
    <v-icon small :color="iconColor()" class="pr-1">{{ icon }}</v-icon>
    <span class="pr-1 white--text">{{ text }}</span>
    <v-icon small color="info" :class="showClose()" @click="remove"
      >mdi-close-circle</v-icon
    >
  </v-chip>
</template>

<script>
export default {
  name: "applied-filter",
  data: function () {
    return {
      hover: false,
    };
  },
  props: {
    icon: {
      type: String,
      default: "mdi-account-circle",
    },
    text: {
      type: String,
      default: "Filter",
    },
  },
  methods: {
    remove() {
      this.$emit("removeFilter", this.text);
    },
    showClose() {
      if (this.hover) {
        return "ml-2 d-flex";
      } else {
        return "ml-2 d-none";
      }
    },
    iconColor() {
      if (this.hover) {
        return "white";
      } else {
        return "accent";
      }
    },
  },
};
</script>
