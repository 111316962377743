import { render, staticRenderFns } from "./ConversationList.vue?vue&type=template&id=f9203344&"
import script from "./ConversationList.vue?vue&type=script&lang=js&"
export * from "./ConversationList.vue?vue&type=script&lang=js&"
import style0 from "./ConversationList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDataIterator,VExpansionPanel,VExpansionPanels,VRow})
