<template>
  <div id="helpButton">
    <span v-if="side == 'left'">
      <v-menu
        open-on-hover
        :nudge-top="16"
        :nudge-right="nudgeRight"
        :nudge-bottom="0"
        :nudge-left="0"
        left
        offset-x
        id="helpMenu"
        attach="#helpButton"
      >
        <template v-slot:activator="{ on }">
          <v-icon color="#58ABE7" v-on="on" id="mainIcon"
            >mdi-help-circle</v-icon
          >
        </template>
        <v-card color="secondary" :width="400">
          <v-card-title class="body-1 #58ABE7--text">
            <v-icon
              class="mr-5"
              color="#58ABE7"
              style="position: absolute; right: 0px"
              >mdi-help-circle</v-icon
            >{{ title }}
          </v-card-title>
          <v-card-text>
            <span v-html="content" class="white--text"></span>
          </v-card-text>
        </v-card>
      </v-menu>
    </span>
    <span v-if="side == 'right'">
      <v-menu
        open-on-hover
        :nudge-top="17"
        :nudge-right="0"
        :nudge-bottom="0"
        :nudge-left="39"
        right
        offset-x
        id="helpMenu"
        attach="#helpButton"
        style="overflow: unset"
      >
        <template v-slot:activator="{ on }">
          <v-icon color="#58ABE7" v-on="on" id="mainIcon"
            >mdi-help-circle</v-icon
          >
        </template>
        <v-card color="secondary" :width="400">
          <v-card-title class="body-1 #58ABE7--text ml-8">
            <v-icon
              class="mr-5"
              color="#58ABE7"
              style="position: absolute; left: 15px"
              >mdi-help-circle</v-icon
            >{{ title }}
          </v-card-title>
          <v-card-text>
            <span v-html="content" class="white--text"></span>
          </v-card-text>
        </v-card>
      </v-menu>
    </span>
  </div>
</template>

<script>
export default {
  name: "help-button",
  data: () => ({
    nudgeRight: 44,
    windowWidth: window.innerWidth,
  }),
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },

    side: {
      type: String,
      default: null,
    },
  },
  mounted() {
    if (this.windowWidth >= 801) {
      this.nudgeRight = 44;
    } else {
      this.nudgeRight = 82;
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    windowWidth(width) {
      if (width >= 801) {
        this.nudgeRight = 44;
      } else {
        this.nudgeRight = 82;
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    hoverEffect(v) {
      //console.debug(v);
    },
  },
};
</script>
