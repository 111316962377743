<template>
  <div class="search-bar mt-8">
    <v-form @submit="queryStringHandler">
      <v-row class="mb-0 d-flex align-center">
        <v-col v-if="this.useTwoInputs == false" cols="8" class="pb-0">
          <v-text-field
            v-model="queryString"
            solo
            flat
            hide-details="true"
            class="rounded-pill"
            :placeholder="placeholder"
            autocomplete="off"
          ></v-text-field>
        </v-col>

        <v-col v-if="this.useTwoInputs" cols="4" class="pb-0">
          <v-text-field
            v-model="queryString"
            solo
            flat
            hide-details="true"
            class="rounded-pill"
            :placeholder="placeholder"
            autocomplete="off"
          ></v-text-field>
        </v-col>

        <v-col v-if="this.useTwoInputs" cols="4" class="pb-0">
          <v-text-field
            v-model="queryStringTwo"
            solo
            flat
            hide-details="true"
            class="rounded-pill"
            :placeholder="placeholderTwo"
            autocomplete="off"
          ></v-text-field>
        </v-col>

        <v-col v-if="useSearchButtonIcon === false" cols="2" class="pb-0">
          <v-btn
            v-if="queryString.length < minLength"
            :disabled="true"
            class="search-bar-btn"
            >{{ label }}</v-btn
          >
          <app-button
            v-if="queryString.length >= minLength"
            buttonType="submit"
            class="search-bar-btn"
            >{{ label }}</app-button
          >
        </v-col>

        <v-col v-if="useSearchButtonIcon === true" cols="1" class="pt-6 pl-0">
          <!-- <v-icon
               v-if="queryString.length < minLength"
               :disabled="true"
               large
               color="accent"
               >mdi-card-search</v-icon
               >
               <v-btn
               v-if="queryString.length >= minLength"
               icon
               buttonType="submit"
               :ripple="false"
               class="ma-0 float-left"
               color="accent"
               >
               <v-icon large>mdi-card-search</v-icon>
               </v-btn> -->
        </v-col>

        <v-col cols="2" class="pb-0">
          <slot></slot>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";

export default {
  name: "search-bar",
  data: () => ({
    queryString: "",
    queryStringTwo: "",
  }),
  props: {
    placeholder: {
      type: String,
      default: "The Search Terms",
    },
    placeholderTwo: {
      type: String,
      default: "The Search Terms Two",
    },
    submitHandler: {
      type: Function,
      default: (e) => console.log(e),
    },
    useSearchButtonIcon: {
      type: Boolean,
      default: false,
    },
    useTwoInputs: {
      type: Boolean,
      default: false,
    },
    clearInput: {
      type: Boolean,
      default: false,
    },
    startValue: {
      type: String,
      default: "",
    },
    startValueTwo: {
      type: String,
      default: "",
    },
    minLength: {
      type: Number,
      default: 3,
    },
    minLength: {
      type: Number,
      default: 3,
    },
    label: {
      type: String,
      default: "Search",
    },
  },
  components: {
    AppButton,
  },
  mounted() {
    this.queryString = this.startValue;
    this.queryStringTwo = this.startValueTwo;
  },
  watch: {
    clearInput: function (val) {
      if (val) {
        this.queryString = "";
        this.queryStringTwo = "";
      }
    },
    startValue: function (val) {
      if (val) {
        this.queryString = val;
      }
    },
  },
  methods: {
    queryStringHandler: function (e) {
      e.preventDefault();
      if (this.useTwoInputs) {
        this.submitHandler([this.queryString, this.queryStringTwo]);
      } else {
        this.submitHandler(this.queryString);
      }
    },
  },
};
</script>

<style scoped>
.search-bar-btn {
  height: 34px;
  width: 140px;
}

.v-btn:before {
  display: none;
}
</style>
