<template>
  <div>
    <v-btn
      depressed
      medium
      :color="color"
      :type="buttonType"
      @click="clickHandler"
      :disabled="disabled"
    >
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "app-button",
  props: {
    placeholder: {
      type: String,
      default: "accent",
    },
    buttonType: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "accent",
    },
    clickHandler: {
      type: Function,
      default: () => console.log(""),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
