<template>
  <div class="sortList">
    <v-select
      :items="items"
      solo
      :dense="true"
      color="#58abe7"
      @change="selection"
      v-model="item"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "sort-list",
  props: {
    clearInput: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    selection: function (value) {
      this.$emit("input", value);
    },
  },
  data: () => ({
    item: null,
  }),
  created() {
    if (this.value) {
      this.item = this.value;
    }
  },
  watch: {
    clearInput: function (val) {
      if (val) {
        this.item = this.items[0];
      }
    },
  },
};
</script>
<style scoped>
/* The Vuetify "Select" component doesn't give us enough control over
 all of the elements inside of component, so we need to either
 override some theme classes here (less readable) or we need extend
 the component and add our own style options (alongside flat, solo,
 etc.) (more difficult). */

.sortList >>> .v-select__selection.v-select__selection--comma {
  color: #58abe7 !important;
  font-size: 14px !important;
}

.sortList
  >>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}

.sortList >>> .v-icon {
  color: #58abe7 !important;
}

.sortList >>> .v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent !important;
}
</style>
