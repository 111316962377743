<template>
  <v-card
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="selectConversation(preview.convoId)"
    flat
    class="mr-10 mb-2 body-2 pa-3"
    :color="background()"
  >
    <v-row>
      <v-col cols="10">
        <span v-html="preview.convo"></span>
      </v-col>
      <v-col cols="2">
        <v-icon v-if="hover" small color="white">mdi-close-circle</v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "cart-item",
  data: function () {
    return {
      hover: false,
    };
  },
  props: {
    preview: {
      type: Object,
      default: function () {
        return { convo: "", convoId: "" };
      },
    },
    selectConversation: {
      type: Function,
      default: function (convoId) {
        //console.log("...", convoId);
      },
    },
  },
  methods: {
    background: function () {
      if (this.hover) {
        return "rgb(12, 29, 55)";
      } else {
        return "secondary";
      }
    },
  },
};
</script>
