var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.messages),function(msg,index){return _c('v-row',{key:index,attrs:{"id":msg.id}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"pr-6 pl-6 pt-2 pb-2",attrs:{"flat":""}},[_c('v-row',{staticClass:"body-2 pa-0 mt-1"},[_c('a',{staticStyle:{"cursor":"pointer","text-decoration":"none","color":"#fff"},attrs:{"target":"_blank","href":'/darkrecon/networks/' +
              _vm.networkId +
              '/sources/' +
              _vm.sourceId +
              '/authors/' +
              msg.author.id},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{class:_vm.fontColor(msg.id)},[_vm._v(_vm._s(msg.author.name))])])]),_c('v-row',{staticClass:"body-2 pa-0 mb-0"},[_vm._v(_vm._s(_vm.formatDate(msg.date)))]),(!_vm.isAuthorDetail)?_c('a',{staticStyle:{"cursor":"pointer","text-decoration":"none"},attrs:{"href":'/darkrecon/conversations/' +
            _vm.conversationId +
            '/messages/' +
            msg.id},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"ml-n4",attrs:{"color":"accent","small":""}},[_vm._v("mdi-link-variant")])],1):_vm._e(),(!_vm.isAuthorDetail)?_c('a',{staticStyle:{"cursor":"pointer","text-decoration":"none"},attrs:{"href":'/darkrecon/conversations/' +
            _vm.conversationId +
            '/messages/' +
            msg.id +
            '/screenshots'},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"accent","small":""}},[_vm._v("mdi-image-size-select-actual")])],1):_vm._e(),(_vm.isAuthorDetail)?_c('a',{staticStyle:{"cursor":"pointer","text-decoration":"none"},attrs:{"href":'/darkrecon/conversations/' +
            _vm.conversationId +
            '/messages/' +
            msg.id},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"ml-n4",attrs:{"color":"accent","small":""}},[_vm._v("mdi-link-variant")])],1):_vm._e(),(_vm.isAuthorDetail)?_c('a',{staticStyle:{"cursor":"pointer","text-decoration":"none"},attrs:{"href":'/darkrecon/conversations/' +
            _vm.conversationId +
            '/messages/' +
            msg.id +
            '/screenshots'},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"accent","small":""}},[_vm._v("mdi-image-size-select-actual")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-card',{staticClass:"pa-2 body-2",class:(!_vm.isPermalinkDetail && _vm.borderTopColor(msg.id)) ||
          (_vm.isPermalinkDetail && _vm.isMessageDetail && _vm.borderTopColor(msg.id)),attrs:{"flat":""}},_vm._l((msg.body),function(body,index){return _c('div',{key:index},[(body.type == 'quoted' && body.content != null)?_c('div',{staticClass:"ma-1 mt-1"},[_c('v-card',{staticClass:"ml-auto mr-auto",attrs:{"color":"info","max-width":"90%","rouned":"","flat":""}},[_c('div',{staticClass:"pa-2"},[_c('span',[_vm._v(_vm._s(body.content))])])])],1):_vm._e(),(body.type == 'authored' && body.content != null)?_c('div',{staticClass:"ma-2 mt-0 mb-0"},[_c('span',[_vm._v(_vm._s(body.content))])]):_vm._e(),(body.type == 'signature' && body.content != null)?_c('div',{staticClass:"ma-2 mt-0 mb-0"},[_c('v-divider',{staticClass:"mt-1 mb-1",attrs:{"light":""}}),_c('span',{staticClass:"ml-1 caption"},[_vm._v(_vm._s(body.content))])],1):_vm._e()])}),0)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }