<script>
import { VAutocomplete } from "vuetify/lib";

export default {
  extends: VAutocomplete,
  name: "autocomplete",
  mounted: function () {
    /* console.log(this.$refs)
     * console.log(this.isMenuActive); */
    /* this.isMenuActive = true; */
  },
  methods: {},
};
</script>
