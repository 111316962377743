<template>
  <div>
    <v-row v-for="(msg, index) in messages" :key="index" :id="msg.id">
      <v-col cols="3">
        <v-card flat class="pr-6 pl-6 pt-2 pb-2">
          <v-row class="body-2 pa-0 mt-1">
            <a
              style="cursor: pointer; text-decoration: none; color: #fff"
              target="_blank"
              v-on:click.stop
              :href="
                '/darkrecon/networks/' +
                networkId +
                '/sources/' +
                sourceId +
                '/authors/' +
                msg.author.id
              "
              ><span :class="fontColor(msg.id)">{{ msg.author.name }}</span></a
            ></v-row
          >
          <v-row class="body-2 pa-0 mb-0">{{ formatDate(msg.date) }}</v-row>
          <a
            v-if="!isAuthorDetail"
            style="cursor: pointer; text-decoration: none"
            v-on:click.stop
            :href="
              '/darkrecon/conversations/' +
              conversationId +
              '/messages/' +
              msg.id
            "
          >
            <v-icon color="accent" small class="ml-n4"
              >mdi-link-variant</v-icon
            ></a
          >
          <a
            v-if="!isAuthorDetail"
            style="cursor: pointer; text-decoration: none"
            v-on:click.stop
            :href="
              '/darkrecon/conversations/' +
              conversationId +
              '/messages/' +
              msg.id +
              '/screenshots'
            "
          >
            <v-icon color="accent" small class="ml-2"
              >mdi-image-size-select-actual</v-icon
            >
          </a>
          <a
            v-if="isAuthorDetail"
            style="cursor: pointer; text-decoration: none"
            v-on:click.stop
            :href="
              '/darkrecon/conversations/' +
              conversationId +
              '/messages/' +
              msg.id
            "
          >
            <v-icon color="accent" small class="ml-n4"
              >mdi-link-variant</v-icon
            ></a
          >
          <a
            v-if="isAuthorDetail"
            style="cursor: pointer; text-decoration: none"
            v-on:click.stop
            :href="
              '/darkrecon/conversations/' +
              conversationId +
              '/messages/' +
              msg.id +
              '/screenshots'
            "
          >
            <v-icon color="accent" small class="ml-2"
              >mdi-image-size-select-actual</v-icon
            >
          </a>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card
          flat
          :class="
            (!isPermalinkDetail && borderTopColor(msg.id)) ||
            (isPermalinkDetail && isMessageDetail && borderTopColor(msg.id))
          "
          class="pa-2 body-2"
        >
          <div v-for="(body, index) in msg.body" :key="index">
            <div
              class="ma-1 mt-1"
              v-if="body.type == 'quoted' && body.content != null"
            >
              <v-card
                color="info"
                max-width="90%"
                class="ml-auto mr-auto"
                rouned
                flat
              >
                <div class="pa-2">
                  <span>{{ body.content }}</span>
                </div>
              </v-card>
            </div>
            <div
              class="ma-2 mt-0 mb-0"
              v-if="body.type == 'authored' && body.content != null"
            >
              <span>{{ body.content }}</span>
            </div>
            <div
              class="ma-2 mt-0 mb-0"
              v-if="body.type == 'signature' && body.content != null"
            >
              <v-divider light class="mt-1 mb-1"></v-divider>
              <span class="ml-1 caption">{{ body.content }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import { formatDate } from "@/utils/common";
import store from "../store/index";
export default {
  name: "conversation-detail",
  data: () => ({
    msgId: null,
    moment: moment,
    formatDate: formatDate,
  }),
  props: {
    messages: {
      type: Array,
      default: () => {
        [];
      },
    },
    isPermalinkDetail: {
      type: Boolean,
      default: false,
    },
    isMessageDetail: {
      type: Boolean,
      default: false,
    },
    isAuthorDetail: {
      type: Boolean,
      default: false,
    },
    networkId: {
      type: String,
      default: "",
    },
    sourceId: {
      type: String,
      default: "",
    },
    conversationId: {
      type: String,
      default: "",
    },
  },
  computed: {
    relevant_msg() {
      if (this.messages.filter((r) => r.most_relevant == true).length == 1) {
        return this.messages.filter((r) => r.most_relevant == true)[0];
      } else {
        return this.messages[0];
      }
    },
  },
  mounted() {
    if (
      this.isMessageDetail == true &&
      this.isAuthorDetail == false &&
      this.relevant_msg
    ) {
      this.scrollToMessage(this.relevant_msg.id);
    }
  },
  methods: {
    scrollToMessage(id) {
      const yOffset = -150;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    fontColor() {
      return "";
    },
    borderTopColor(id) {
      if (
        (id == this.relevant_msg.id && this.isPermalinkDetail == false) ||
        (id == this.relevant_msg.id &&
          this.isPermalinkDetail == true &&
          this.isMessageDetail == true)
      ) {
        return "borderTopColor";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.borderTopColor {
  border-top: solid 5px #e86051;
}
.linkText:hover {
  text-decoration: underline;
}
</style>
