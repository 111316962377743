<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" class="pa-1">
        <span v-for="filter in chooseFilters">
          <choose-filter
            :text="filter.text"
            :icon="filter.icon"
            :count="filter.count"
            :items="filter.items"
            @selectFilter="updateFilterSelection"
          >
          </choose-filter>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-1">
        <span v-for="filter in appliedFilters">
          <applied-filter
            :text="filter.text"
            :icon="filter.icon"
            @removeFilter="removeFilterSelection"
          ></applied-filter>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChooseFilter from "@/components/ChooseFilter";
import AppliedFilter from "@/components/AppliedFilter";

export default {
  name: "filter-bar",
  components: {
    AppliedFilter,
    ChooseFilter,
  },
  props: {
    filters: {
      type: Array,
      default: function () {
        return [
          {
            text: "Author",
            icon: "mdi-account-circle",
            items: ["Author one", "Author two", "Author three"],
          },
          {
            text: "Network",
            icon: "mdi-public",
            items: ["Network one", "Network two", "Network three"],
          },
          {
            text: "Source",
            icon: "mdi-acount-circle",
            items: ["Source one", "Source two", "Source three"],
          },
        ];
      },
    },
  },
  computed: {
    chooseFilters: function () {
      const theFilters = [];
      for (const filter of this.filters) {
        const newFilter = { ...filter };
        const optionLength = filter.items.length;
        const optionSelected = this.appliedFilters.filter(
          (anAppliedFilter) => anAppliedFilter.name == filter.text
        ).length;
        newFilter.count = `${optionSelected}/${optionLength}`;
        theFilters.push(newFilter);
      }
      return theFilters;
    },
  },
  data: function () {
    return {
      appliedFilters: [],
    };
  },
  methods: {
    updateFilterSelection(value, filterName, filterIcon) {
      const filterValues = this.appliedFilters.map(
        (anAppliedFilter) => anAppliedFilter.text
      );
      if (!filterValues.includes(value)) {
        this.appliedFilters.push({
          text: value,
          icon: filterIcon,
          name: filterName,
        });
      }
      this.$emit("updateFilters", this.appliedFilters);
    },
    removeFilterSelection(filterName) {
      const newAppliedFilters = this.appliedFilters.filter(
        (anAppliedFilter) => anAppliedFilter.text != filterName
      );

      this.appliedFilters = newAppliedFilters;
      this.$emit("updateFilters", this.appliedFilters);
    },
  },
};
</script>
