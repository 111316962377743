<template>
  <v-menu offset-y :close-on-click="true" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        ref="chip"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        class="mx-2 chooseFilter"
        color="info"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small :color="iconColor()" class="pr-1">{{ icon }}</v-icon>
        <span class="pr-1">{{ text }}</span>
        <span>{{ count }}</span>
      </v-chip>
    </template>
    <v-card>
      <autocomplete
        v-slot:selection=""
        v-model="select"
        :items="items"
        class="choose-filter"
        filled
      >
      </autocomplete>
    </v-card>
  </v-menu>
</template>

<script>
import Autocomplete from "@/components/Autocomplete.vue";

export default {
  name: "choose-filter",
  components: {
    Autocomplete,
  },
  data: function () {
    return {
      hover: false,
      select: null,
    };
  },
  props: {
    icon: {
      type: String,
      default: "mdi-public",
    },
    text: {
      type: String,
      default: "Filter",
    },
    count: {
      type: String,
      default: "0/10",
    },
    items: {
      type: Array,
      default: function () {
        return ["Option 1", "Option 2", "Option 3"];
      },
    },
  },
  methods: {
    iconColor() {
      if (this.hover) {
        return "secondary";
      } else {
        return "accent";
      }
    },
  },
  watch: {
    select: function (newVal, oldVal) {
      if (this.select !== null) {
        this.$emit("selectFilter", newVal, this.text, this.icon);
      }
      this.select = null;
    },
  },
};
</script>
