<template>
  <div>
    <v-row
      style="
        margin-top: 0em;
        margin-left: auto;
        margin-right: auto;
        max-width: 940px;
      "
      v-if="type == 'table'"
    >
      <v-skeleton-loader
        v-if="header"
        class="mx-auto"
        max-width="940px"
        width="940px"
        type="table-thead"
        style="margin-top: 1em; margin-bottom: 1em"
        dark
      ></v-skeleton-loader>

      <div v-for="(row, index) in 6" :key="index">
        <v-skeleton-loader
          class="mx-auto"
          max-width="940px"
          width="940px"
          type="divider"
          style="margin-top: 1em; margin-bottom: 1em"
          dark
        ></v-skeleton-loader>

        <v-skeleton-loader
          class="mx-auto"
          max-width="940px"
          width="940px"
          type="text"
          style="margin-top: 1em; margin-bottom: 1em"
          dark
        ></v-skeleton-loader>
      </div>
    </v-row>

    <v-row
      style="
        margin-top: 0em;
        margin-left: auto;
        margin-right: auto;
        max-width: 960px;
      "
      v-if="type == 'card'"
      :class="{ sklCardHeader: header, sklCardNoHeader: !header }"
    >
      <v-skeleton-loader
        v-if="header"
        width="966px"
        type="article,  table-cell@2"
      >
      </v-skeleton-loader>

      <v-row v-if="!header">
        <v-col cols="3" class="metaLoader">
          <v-skeleton-loader type="article"> </v-skeleton-loader>
        </v-col>
        <v-col cols="9">
          <v-skeleton-loader type="article"> </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "skeleton-loader",
  props: {
    header: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "table",
    },
  },
};
</script>

<style scoped>
.sklCardHeader >>> .v-skeleton-loader__table-cell.v-skeleton-loader__bone {
  margin-top: -3em;
  margin-left: 1em;
  width: 60px;
}
.sklCardHeader
  >>> .v-skeleton-loader__table-cell.v-skeleton-loader__bone:nth-child(2) {
  margin-top: -3em;
  margin-left: 12em;
  width: 60px;
}
.sklCardHeader >>> .v-skeleton-loader__article.v-skeleton-loader__bone {
  min-height: 155px;
}
.sklCardHeader >>> .v-skeleton-loader__paragraph.v-skeleton-loader__bone {
  margin-top: -0.75em;
}
.sklCardHeader
  >>> .v-skeleton-loader__paragraph
  .v-skeleton-loader__text:nth-child(2) {
  max-width: 100%;
  margin-top: 1em;
}
.sklCardHeader
  >>> .v-skeleton-loader__paragraph
  .v-skeleton-loader__text:nth-child(3) {
  display: none;
}
.sklCardHeader >>> .v-skeleton-loader__heading.v-skeleton-loader__bone {
  max-width: 150px;
}
.sklCardNoHeader >>> .v-skeleton-loader__heading.v-skeleton-loader__bone {
  display: none;
}
.sklCardNoHeader
  >>> .v-skeleton-loader__paragraph
  .v-skeleton-loader__text:nth-child(2) {
  max-width: 100%;
  margin-top: 0.75em;
}
.sklCardNoHeader
  >>> .v-skeleton-loader__paragraph
  .v-skeleton-loader__text:nth-child(3) {
  max-width: 40%;
  margin-top: 0.75em;
}
.sklCardNoHeader >>> .v-skeleton-loader__article.v-skeleton-loader__bone {
  min-height: 123px;
}
</style>
